<template>
  <div class="books">
    <ag-table :data-source-api="getAllBooks" :grid-options="gridOptions" :permissions="permissions" :rtl="false"
      :is-entities-response="false" @emitAddNewRecord="onCreateBook()">
    </ag-table>
    <tm-popup :submit="onBookPopupSubmit" :title="bookPopupTitle" ref="bookPopup">
      <div class="vx-row justify-start">
        <div class="vx-col sm:w-1/2 w-full mb-2 mt-2">
          <tm-input class="w-full" name="اسم الكتاب" placeholder="اسم الكتاب" type="text" validation-rules="required"
            v-model="book.title" />
        </div>
        <div class="vx-col sm:w-1/2 w-full mb-2 mt-2">
          <tm-input class="w-full" name="اسم الكاتب" placeholder="اسم الكاتب" type="text" validation-rules="required"
            v-model="book.author" />
        </div>
        <div class="vx-col w-full mb-2 mt-2">
          <tm-input class="w-full" name="رابط فيديو الملخص" placeholder="رابط فيديو الملخص" type="text"
            :validation-rules="{ required: false, regex: /^(https?\:\/\/)?((www\.)?youtube\.com|youtu\.be)\/.+$/ }"
            v-model="book.summeryVideoUrl" />
        </div>
        <div class="vx-col w-full">
          <p class="mt-2 px-2" style="font-size: smaller;">
            صورة الغلاف
          </p>
          <file-pond-component :file="book.imageUrl" @fileDeleted="book.imageUrl = null"
            @fileUploaded="file => book.imageUrl = file" :rules="{ required: true }" project="website" />
        </div>
        <div class="vx-col w-full mb-2 mt-4">
          <p class="mt-2 px-2" style="font-size: smaller;">
            ملخص الكتاب
          </p>
          <tm-text-area class="w-full" name="ملخص الكتاب" validation-rules="required" v-model="book.description" />
        </div>
      </div>
    </tm-popup>
  </div>
</template>

<script>
import { RepositoryFactory } from '../zadi-repositories/zadiRepositoryFactory';
import AgTable from '../../shared/shared-components/ag-grid/AgTableServerSide.vue';
import AgTableBtnCell from "../../shared/shared-components/ag-grid/AgTableBtnCell";
import AgButton from "../../shared/shared-components/ag-grid/AgButton";
import AgTableImageCell from "@/app/shared/shared-components/ag-grid/AgTableImageCell";
import TmPopup from "@/app/shared/shared-components/TmPopup";
import TmInput from "@/app/shared/shared-components/TmInput";
import FilePondComponent from "@/app/shared/shared-components/FilePondComponent";
import TmTextArea from "@/app/shared/shared-components/TmTextArea";

const zadiRepo = RepositoryFactory.get('zadiRepository');
export default {
  name: "Books",
  components: {
    AgTable,
    TmInput,
    AgTableBtnCell,
    TmPopup,
    AgButton,
    TmTextArea,
    FilePondComponent
  },
  data() {
    return {
      gridApi: null,
      gridOptions: null,
      bookPopupTitle: '',
      book: {
        description: null,
        author: null,
        imageUrl: null,
        summeryVideoUrl: null,
        title: null,
      },
      permissions: {
        add: 'books_management',
        edit: 'books_management',
        delete: 'books_management',
      }
    };
  },
  methods: {
    createColDefs() {
      let self = this;
      return [
        {
          headerName: 'صورة الكاتب',
          field: 'imageUrl',
          sortable: false,
          cellRendererFramework: AgTableImageCell,
          cellRendererParams: {
            width: '90%'
          },
          width: 200,
        },
        {
          headerName: 'اسم الكتاب',
          field: 'title',
          filter: 'agTextColumnFilter',
          width: 200,
          sortable: false
        },
        {
          headerName: 'اسم الكاتب',
          field: 'author',
          filter: 'agTextColumnFilter',
          width: 200,
          sortable: false
        },
        {
          headerName: 'رابط الكتاب',
          field: 'summeryVideoUrl',
          width: 210,
          sortable: false,
          cellRendererFramework: AgButton,
          valueGetter:
            function getObject(params) {
              return params.data.summeryVideoUrl;
            },
          cellRendererParams: {
            size: 'small',
            color: 'primary',
            name: 'الانتقال الى فيديو الملخص',
            type: 'gradient',
            click(valueGetter) {
              self.onOpenBookSummeryVideo(valueGetter);
            }
          },
        },
        {
          headerName: 'الإجراءات',
          field: 'id',
          cellRendererFramework: AgTableBtnCell,
          sortable: false,
          cellRendererParams: {
            deleteRecord: function (id) {
              self.onDeleteBook(id);
            },
            editRecord: function (book) {
              self.onUpdateBook(book);
            },
            actions: ['edit', 'delete'],
            permissions: self.permissions
          },
        }
      ];
    },
    async onDeleteBook(bookId) {
      await zadiRepo.removeBook(bookId);
      await this.gridApi.refreshServerSideStore({ purge: true });
    },
    onOpenBookSummeryVideo(summeryVideoUrl) {
      window.open(summeryVideoUrl, '_blank').focus();
    },
    onUpdateBook(book) {
      this.bookPopupTitle = 'تعديل معلومات الملخص';
      this.resetBookForm();
      Object.assign(this.book, book);
      this.$refs.bookPopup.open();
    },
    onCreateBook() {
      this.bookPopupTitle = 'إضافة ملخص جديد';
      this.resetBookForm();
      this.$refs.bookPopup.open();
    },
    resetBookForm() {
      Object.assign(this.book, {
        description: null,
        author: null,
        imageUrl: null,
        summeryVideoUrl: null,
        title: null,
      });
    },
    async onBookPopupSubmit() {
      try {
        let res = null;
        if (this.bookPopupTitle == 'إضافة ملخص جديد') {
          res = await zadiRepo.createBook(this.book);
        } else {
          res = await zadiRepo.updateBook(this.book);
        }

        if (res.status === 201 || res.status === 200) {
          this.$refs.bookPopup.close();
          await this.gridApi.refreshServerSideStore({ purge: true });
        }
      } catch (err) {
        this.$refs.bookPopup.endLoading();
      }
    },
    async getAllBooks(query) {
      return await zadiRepo.fetchBooks(query);
    },
  },
  created() {
    this.gridOptions = {};
    this.gridOptions.columnDefs = this.createColDefs();
  },
  mounted() {
    this.gridApi = this.gridOptions.api;
  }
};
</script>