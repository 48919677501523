import zadiRepository from "./zadiRepository";


export const repositories = {
  zadiRepository,
};

export const RepositoryFactory = {
  get: name => repositories[name]
};
