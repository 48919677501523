import { httpClient } from "@/app/shared/shared-services/httpClient";
import { environment } from "@/environment";

const URL = environment.zadiApiBaseUrl;


export default {
  async fetchMagazines(query) {
    const url = `${URL}v1/magazines${query}`;
    return (await httpClient.get(url)).data;
  },
  async createMagazine(magazine) {
    const url = `${URL}v1/magazines`;
    return await httpClient.post(url, magazine);
  },
  async updateMagazine(magazine) {
    const url = `${URL}v1/magazines/${magazine.id}`;
    return await httpClient.put(url, magazine);
  },
  async removeMagazine(magazineId) {
    const url = `${URL}v1/magazines/${magazineId}`;
    return await httpClient.delete(url);
  },
  async fetchBooks(query) {
    const url = `${URL}v1/books${query}`;
    return (await httpClient.get(url)).data;
  },
  async createBook(book) {
    const url = `${URL}v1/books`;
    return await httpClient.post(url, book);
  },
  async updateBook(book) {
    const url = `${URL}v1/books/${book.id}`;
    return await httpClient.put(url, book);
  },
  async removeBook(bookId) {
    const url = `${URL}v1/books/${bookId}`;
    return await httpClient.delete(url);
  },
};
