<template>
  <ValidationProvider
    :name="name"
    :rules="validationRules"
    v-slot="{ errors }"
    class="w-full"
  >
    <vs-textarea
      class="textarea-container"
      :label="name"
      v-model="computedValue"
    />
    <span class="text-danger">{{ errors[0] }}</span>
  </ValidationProvider>
</template>

<script>
export default {
  name: "TmTextArea",
  props: {
    name: {type: String, required: false, default: null},
    value: {type: String, required: true},
    validationRules: {type: String, required: true}
  },
  computed: {
    computedValue: {
      get() {
        return this.value;
      },
      set(newValue) {
        this.$emit("input", newValue);
      }
    }
  },
};
</script>

<style>
  .textarea-container textarea {
    min-height: 100px;
  }
</style>